<template>
    <span
        class="tooltip"
        @mouseenter.self="isHover = true"
        @mouseleave.self="isHover = false">
        <slot></slot>
        <span
            v-if="isHover && active"
            class="tooltip-input">
            <CustomInput
                v-model="_value"
                :label="null"
                copy
                disabled
                expanded></CustomInput>
        </span>
    </span>
</template>

<script>
  import CustomInput from "@/components/Common/Select/CustomInput.vue";

  export default {
    name: "CopyTooltip",
    components: {
      CustomInput
    },

    props: {
      value: {
        type: String,
        default: null
      },

      active: {
        type: Boolean,
        default: true
      }
    },

    data () {
      return {
        isHover: false
      };
    },

    computed: {
      _value: {
        get () {
          return this.value;
        },

        set (value) {
          this.$emit("input", value);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
    @import "src/scss/theme-default";

    .tooltip {
        cursor: pointer;
        position: relative;

        &:before {
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            position: absolute;
        }

        &-input {
            top: 50%;
            z-index: 1;
            min-width: 310px;
            position: absolute;
            display: inline-block;
            border: 1px solid lightgray;
            border-radius: $radius-small;
            transform: translate(25px, -50%);

            &:after {
                content: "";
                top: 0;
                right: 100%;
                width: 25px;
                height: 100%;
                position: absolute;
            }

            ::v-deep {
                .input, .button {
                    font-size: 0.75rem;
                    font-family: $family-monospace;
                }
            }
        }
    }
</style>
